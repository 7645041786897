import { WasteReceptionGroup } from './WasteReceptionGroup';
import { WasteReceptionItem } from './WasteReceptionItem';

export enum ReceptionStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export interface WasteReception {
  id?: string;

  propertyId: string;

  propertyName?: string;

  residentCardId?: string;

  residentCardNumber?: string;

  status: ReceptionStatus;

  items: WasteReceptionItem[];

  groups?: WasteReceptionGroup[];

  closedAt?: string;
}
