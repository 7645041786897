import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useState } from 'react';
import wasteGroupsService from '../../service/api/WasteGroupsService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { WasteGroup, Unit } from '../../types/WasteGroup';

interface FormValuesProps extends Omit<WasteGroup, 'unit'> {
  unit: Unit | string;
}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  name: '',
  limit: null,
  unit: '',
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  wasteGroup: WasteGroup;
};

function WasteGroupDialog({ dialogVisible, closeAddEditModal, wasteGroup }: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const { t } = useTranslation();
  const units = Object.values(Unit)?.map((u) => {
    return { label: t(`wasteGroups.units.${u}`), value: u };
  });

  useEffect(() => {
    if (dialogVisible && wasteGroup) {
      setInitFormValues({
        id: wasteGroup.id,
        name: wasteGroup.name,
        limit: wasteGroup.limit,
        unit: wasteGroup.unit,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }
      if (!data.unit?.trim()) {
        errors.unit = t('wasteGroups.error_unit_required');
      }
      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      if (!formData.id) {
        wasteGroupsService
          .create(formData as WasteGroup)
          .then((_res: any) => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('wasteGroups.error_name_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        wasteGroupsService
          .edit(formData as WasteGroup, formData.id)
          .then(() => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('wasteGroups.error_name_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('wasteGroups.waste_group_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="limit">{t('wasteGroups.limit')}</label>

              <InputNumber
                id="limit"
                min={1}
                max={1000000}
                value={formik.values.limit}
                onValueChange={formik.handleChange}
                className={formik.touched.limit && formik.errors.limit && 'p-invalid'}
              />
              <FormErrorMessage fieldName="limit" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="unit">{t('wasteGroups.unit')}</label>

              <Dropdown
                id="unit"
                value={formik.values.unit}
                onChange={formik.handleChange}
                options={units}
                placeholder={t('common.select')}
                className={formik.touched.unit && formik.errors.unit && 'p-invalid'}
              />
              <FormErrorMessage fieldName="unit" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default WasteGroupDialog;
