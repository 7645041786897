import axios from 'axios';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';

export const AxiosConfigContainer = () => {
  const loggedUserToken = useSelector((state: any) => state.user?.loggedUser?.accessToken);
  const history = useHistory();
  const toast = useRef<any>();
  const { t } = useTranslation();

  const setUpInterceptors = useCallback(
    async (token: string) => {
      axios.interceptors.request.handlers = [];
      axios.interceptors.response.handlers = [];

      axios.interceptors.request.use(
        function (config: any) {
          config.baseURL = process.env.REACT_APP_BACKEND_URL;
          if (process.env.REACT_APP_BACKEND_PATH && process.env.REACT_APP_BACKEND_PORT) {
            config.baseURL = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_BACKEND_PORT}/${process.env.REACT_APP_BACKEND_PATH}`;
          } else {
            config.baseURL = process.env.REACT_APP_BACKEND_URL;
          }

          if (token) {
            config.headers.common.Authorization = `Bearer ${token}`;
          }

          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          console.error(error);
          if (!error.response) {
            return Promise.reject(error);
          }

          error.response.status === 401 && history.push('/login');
          error.response.status === 403 &&
            toast.current.show({
              severity: 'error',
              summary: t('common.errorNoPermission'),
              detail: t('common.errorNoPermissionDetails'),
              life: 4000,
            });

          if (error.response.status.toString().charAt(0) === '5') {
            if (error.response.status === 500) {
              history.replace('/error');
            } else {
              const data = error.response.data || {};
              if (!data.statusCode || !data.timestamp || !data.path || !data.error) {
                // 5XX error NOT from our API
                history.replace('/error');
              }
            }
          }
          return Promise.reject(error);
        }
      );
    },
    [history, t]
  );

  useEffect(() => {
    setUpInterceptors(loggedUserToken);
  }, [setUpInterceptors, loggedUserToken]);

  return <Toast ref={toast} />;
};
