const hasPermission = (requiredPermission: any, userPermissions: any) => {
  return userPermissions?.includes(requiredPermission);
};

const hasOneOfPermissions = (requiredPermissions: any, userPermissions: any) => {
  return requiredPermissions
    .map((requiredPermission: any) => {
      return userPermissions?.includes(requiredPermission);
    })
    .reduce((a, b) => {
      return a || b;
    });
};

export { hasPermission, hasOneOfPermissions };
