import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import divisionsService from '../../service/api/DivisionsService';
import clientsService from '../../service/api/ClientsService';
import { Dialog } from 'primereact/dialog';
import DivisionDialog from './DivisionDialog';
import { Trans, useTranslation } from 'react-i18next';
import { Division } from '../../types/Division';
import { Client } from '../../types/Client';

export const DivisionManagement = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [divisions, setDivisions] = useState<Division[]>(null);
  const [divisionToDelete, setDivisionToDelete] = useState<Division>();

  const [clients, setClients] = useState<Client[]>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<Division>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();

  const loadData = () => {
    setLoading(true);
    Promise.all([divisionsService.getAll(), clientsService.getAll()]).then((response) => {
      setClients(response[1]);
      const clientsDict = Object.assign({}, ...response[1].map((r) => ({ [r.id]: r.name })));

      const _divisions = response[0];
      _divisions.forEach((_x) => {
        _x.clientName = clientsDict[_x.clientId];
      });
      setDivisions(_divisions);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const divisionsTableHeader = (
    <div className="table-header">
      {t('divisions.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addDivision();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteDivision = (division: Division) => {
    setDivisionToDelete(division);
    setDeleteDialogVisible(true);
  };

  const deleteDivision = () => {
    setLoading(true);
    divisionsService.delete(divisionToDelete.id).then(() => {
      loadData();
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editDivision = (_division: Division) => {
    setSelectedRowToEdit(_division);
    setAddEditDialogVisible(true);
  };

  const addDivision = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteDivision()} />
    </>
  );

  const bodyTemplate = (data: Division, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: Division) => (
    <span>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip={t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editDivision(data);
        }}
      ></Button>
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          confirmDeleteDivision(data);
        }}
      ></Button>
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={divisions}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('divisions.empty_message')}
            loading={loading}
            header={divisionsTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>
            <Column field="clientName" header={t('divisions.client')} sortable body={bodyTemplate}></Column>

            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <DivisionDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            division={selectedRowToEdit}
            availableClients={clients}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {divisionToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: divisionToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
