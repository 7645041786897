import axios from 'axios';
import { WasteType } from '../../types/WasteType';

class WasteTypesService {
  async getAll(): Promise<WasteType[]> {
    const result = await axios.get('/waste-types');
    return result.data;
  }
  async get(id: string): Promise<WasteType> {
    const result = await axios.get(`/waste-types/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/waste-types/${id}`);
  }
  async create(postData: WasteType): Promise<void> {
    return axios.post(`/waste-types`, mapToRequestBody(postData));
  }
  async edit(postData: WasteType, id: string): Promise<void> {
    return axios.put(`/waste-types/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (wasteType: WasteType): any => {
  const result = {
    id: wasteType.id,
    name: wasteType.name,
    code: wasteType.code,
    limit: wasteType.limit,
    wasteGroupId: wasteType.wasteGroupId,
  };

  return result;
};

const wasteTypesService = new WasteTypesService();
export default wasteTypesService;
