import axios from 'axios';
import { WasteReception } from '../../types/WasteReception';
import { WasteReceptionItem } from '../../types/WasteReceptionItem';
import { PaginationWithDateFilters, PaginatedResult, DateFilters } from '../../types/general-types';

class WasteReceptionsService {
  async getAllActive(): Promise<WasteReception[]> {
    const result = await axios.get('/waste-receptions/?activeOnly=true');
    return result.data;
  }
  async getAllForProperty(propertyId: string): Promise<WasteReception[]> {
    const result = await axios.get(`/waste-receptions/?propertyId=${propertyId}`);

    return result.data;
  }
  async searchClosed(params: PaginationWithDateFilters): Promise<PaginatedResult<WasteReception>> {
    const result = await axios.post('/waste-receptions/search', params);
    return result.data;
  }
  async getReport(params: DateFilters) {
    const result = await axios.post('/waste-receptions/report', params, {
      responseType: 'arraybuffer',
    });
    return result.data;
  }

  async getReceipt(id: string): Promise<Buffer> {
    const result = await axios.get(`/waste-receptions/${id}/receipt`,  {
      responseType: 'arraybuffer',
    });
    return result.data;
  }

  async get(id: string): Promise<WasteReception> {
    const result = await axios.get(`/waste-receptions/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/waste-receptions/${id}`);
  }
  async create(postData: WasteReception): Promise<string> {
    const result = await axios.post(`/waste-receptions`, mapToRequestBody(postData));
    return result.data;
  }
  async edit(postData: WasteReception, id: string): Promise<void> {
    return axios.put(`/waste-receptions/${id}`, mapToRequestBody(postData));
  }
}

const mapItemToRequestBody = (wasteReceptionItem: WasteReceptionItem): any => {
  const result = {
    amount: wasteReceptionItem.amount,
    wasteTypeId: wasteReceptionItem.wasteTypeId,
    weighingNumber: wasteReceptionItem.weighingNumber,
    weighingId: wasteReceptionItem.weighingId,
  };

  return result;
};

const mapToRequestBody = (wasteReception: WasteReception): any => {
  const result = {
    id: wasteReception.id,
    propertyId: wasteReception.propertyId,
    residentCardId: wasteReception.residentCardId,
    status: wasteReception.status,
    items: wasteReception.items?.map(mapItemToRequestBody),
  };

  return result;
};

const wasteReceptionsService = new WasteReceptionsService();
export default wasteReceptionsService;
