export function groupBy<T>(objectArray: T[], property: keyof T): Map<any, T[]> {
  const propertyName = property.toString();
  return objectArray.reduce((acc, obj) => {
    const key = obj[propertyName];
    if (!acc.has(key)) {
      acc.set(key, []);
    }
    acc.get(key).push(obj);
    return acc;
  }, new Map<any, T[]>());
}
