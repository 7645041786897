import React, { useEffect, useState } from 'react';
import { ReceptionStatus, WasteReception } from '../../types/WasteReception';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';

import propertiesService from '../../service/api/PropertiesService';
import residentCardsService from '../../service/api/ResidentCardsService';

import wasteReceptionsService from '../../service/api/WasteReceptionsService';

import WasteReceptionDialog from '../wasteReception/WasteReceptionDialog';
import { ResidentCard } from '../../types/ResidentCard';
import { getFullAddress } from '../../utils/property';
import { WasteTypeDetails } from '../../types/WasteType';
import { WasteGroup } from '../../types/WasteGroup';
import { format, parseISO } from 'date-fns';
import { getFileFromBuffer } from "../_shared/getFile";

type Props = {
  propertyId: string;
  wasteTypes: WasteTypeDetails[];
  wasteGroups: WasteGroup[];
  onSave: VoidFunction;
  cardNumber?: string;
};

export const Receptions = ({ propertyId, wasteTypes, wasteGroups, onSave, cardNumber }: Props) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [wasteReceptions, setWasteReceptions] = useState<WasteReception[]>(null);

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<WasteReception>(null);

  const [residentCards, setResidentCards] = useState<ResidentCard[]>();

  const [preselectedResidentCard, setPreselectedResidentCard] = useState<ResidentCard>(null);

  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);

  const { t } = useTranslation();

  const loadData = (): Promise<void> => {
    setLoading(true);
    return Promise.all([
      wasteReceptionsService.getAllForProperty(propertyId),
      residentCardsService.getAllForProperty(propertyId),
      propertiesService.getAll(),
    ]).then((response) => {
      setResidentCards(response[1]);
      const residentCardsDict = Object.assign({}, ...response[1].map((r) => ({ [r.id]: r.number })));
      const propertiesDict = Object.assign({}, ...response[2].map((p) => ({ [p.id]: getFullAddress(p) })));

      if (cardNumber) {
        const residentCard = response[1].find(card => card.number === cardNumber);
        setPreselectedResidentCard(residentCard);
      }

      const _wasteReceptions = response[0];
      _wasteReceptions.forEach((_x) => {
        _x.residentCardNumber = residentCardsDict[_x.residentCardId];
      });
      _wasteReceptions.forEach((_x) => {
        _x.propertyName = propertiesDict[_x.propertyId];
      });
      setWasteReceptions(_wasteReceptions);

      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId, cardNumber]);

  const wasteReceptionsTableHeader = (
    <div className="table-header">
      {t('wasteReceptions.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addWasteReception();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
    onSave();
  };

  const editWasteReception = async (_wasteReception: WasteReception) => {
    const reception = await wasteReceptionsService.get(_wasteReception.id);
    setSelectedRowToEdit(reception);
    setAddEditDialogVisible(true);
  };

  const viewWasteReception = async (_wasteReception: WasteReception) => {
    const reception = await wasteReceptionsService.get(_wasteReception.id);
    setSelectedRowToEdit(reception);
    setAddEditDialogVisible(true);
  };

  const addWasteReception = async () => {
    const id = await wasteReceptionsService.create({ propertyId: propertyId, status: ReceptionStatus.OPEN, residentCardId: preselectedResidentCard?.id, items: [] });
    const reception = await wasteReceptionsService.get(id);
    setSelectedRowToEdit(reception);
    setAddEditDialogVisible(true);
  };

  const handleReceiptExport = async (reception: WasteReception) => {
    const response = await wasteReceptionsService.getReceipt(reception.id);
    // file name intentionally left blank to open pdf in new tab
    getFileFromBuffer(response, 'application/pdf', null);
  }

  const bodyTemplate = (data: WasteReception, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const dateTemplate = (reception: WasteReception) => {
    return reception.closedAt ? format(parseISO(reception.closedAt), 'yyyy-MM-dd HH:mm:ss') : '';
  };

  const actionTemplate = (data: WasteReception) => (
    <span>
      {data.status === ReceptionStatus.CLOSED &&
      <Button
          type="button"
          icon="pi pi-print"
          tooltip={t('wasteReceptions.print_receipt')}
          tooltipOptions={{ position: 'top' }}
          className="p-button-success mr-2"
          onClick={() => {
            handleReceiptExport(data);
          }}
      ></Button>
      }
      <Button
        type="button"
        icon={data.status === ReceptionStatus.OPEN ? 'pi pi-pencil' : 'pi pi-search'}
        tooltip={data.status === ReceptionStatus.OPEN ? t('common.edit') : t('common.view_details')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          if (data.status === ReceptionStatus.OPEN) {
            editWasteReception(data);
          } else {
            viewWasteReception(data);
          }
        }}
      ></Button>
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={wasteReceptions}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('wasteReceptions.empty_message')}
            loading={loading}
            header={wasteReceptionsTableHeader}
          >
            <Column
              field="residentCardNumber"
              header={t('wasteReceptions.resident_card')}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column field="closedAt" header={t('wasteReceptions.closed_at')} sortable body={dateTemplate}></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <WasteReceptionDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            wasteReception={selectedRowToEdit}
            availableResidentCards={residentCards}
            wasteTypes={wasteTypes}
            wasteGroups={wasteGroups}
            propertyId={propertyId}
          />
        </div>
      </div>
    </div>
  );
};
