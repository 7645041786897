import axios from 'axios';
import { Client } from '../../types/Client';

class ClientsService {
  async getAll(): Promise<Client[]> {
    const result = await axios.get('/clients');
    return result.data;
  }
  async get(id: string): Promise<Client> {
    const result = await axios.get(`/clients/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/clients/${id}`);
  }
  async create(postData: Client): Promise<void> {
    return axios.post(`/clients`, mapToRequestBody(postData));
  }
  async edit(postData: Client, id: string): Promise<void> {
    return axios.put(`/clients/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (client: Client): any => {
  const result = {
    id: client.id,
    name: client.name,
    scaleoCustomerId: client.scaleoCustomerId,
    scaleoClientId: client.scaleoClientId,
    scaleoClientSecret: client.scaleoClientSecret,
  };

  return result;
};

const clientsService = new ClientsService();
export default clientsService;
