import { createSlice } from '@reduxjs/toolkit';

const initialUserContext = {
  firstName: null,
  lastName: null,
  role: null,
  permissions: null,
  language: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    context: initialUserContext,
    loggedUser: null,
  },
  reducers: {
    setLoggedUser(state, value) {
      state.loggedUser = value.payload;
    },
    setLoggedUserContext(state, value) {
      if (value.payload?.language) {
        localStorage.setItem('language', value.payload.language);
      }

      state.context = { ...initialUserContext, ...value.payload };
    },
    setLanguage(state, value) {
      localStorage.setItem('language', value.payload);
      state.context.language = value.payload;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
