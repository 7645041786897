import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import residentCardsService from '../../service/api/ResidentCardsService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { ResidentCard } from '../../types/ResidentCard';
import { Property } from '../../types/Property';
import { getFullAddress } from '../../utils/property';

interface FormValuesProps extends ResidentCard {}
const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  number: '',
  propertyId: null,
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  residentCard: ResidentCard;
  availableProperties: Property[];
};

function ResidentCardDialog({
  dialogVisible,
  closeAddEditModal,
  residentCard,

  availableProperties,
}: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const properties = availableProperties?.map((r) => {
    return { label: getFullAddress(r), value: r.id };
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && residentCard) {
      setInitFormValues({
        id: residentCard.id,
        number: residentCard.number,
        propertyId: residentCard.propertyId,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.number?.trim()) {
        errors.number = t('residentCards.error_number_required');
      }
      if (!data.propertyId) {
        errors.propertyId = t('residentCards.error_property_required');
      }
      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      if (!formData.id) {
        residentCardsService
          .create(formData)
          .then((_res: any) => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('number', t('residentCards.error_number_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        residentCardsService
          .edit(formData, formData.id)
          .then(() => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('number', t('residentCards.error_number_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('residentCards.resident_card_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="number">{t('residentCards.number')}</label>
              <InputText
                id="number"
                value={formik.values.number}
                onChange={formik.handleChange}
                className={formik.touched.number && formik.errors.number && 'p-invalid'}
              />
              <FormErrorMessage fieldName="number" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="propertyId">{t('residentCards.property')}</label>
              <Dropdown
                id="propertyId"
                value={formik.values.propertyId}
                onChange={formik.handleChange}
                options={properties}
                placeholder={t('common.select')}
                emptyMessage={t('residentCards.properties_empty_message')}
                className={formik.touched.propertyId && formik.errors.propertyId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="propertyId" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default ResidentCardDialog;
