const FormErrorMessage = ({ fieldName, formikInstance, className, withTouchedTrue }: any) => {
  const touched = withTouchedTrue || formikInstance.touched[fieldName];
  const isFormFieldInvalid = () => touched && formikInstance.errors[fieldName];

  return isFormFieldInvalid() ? (
    <small id={`error-msg-${fieldName}`} className={className ?? 'p-error'}>
      {formikInstance.errors[fieldName]}
    </small>
  ) : (
    <></>
  );
};

export default FormErrorMessage;
