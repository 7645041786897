import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import clientsService from '../../service/api/ClientsService';
import { Dialog } from 'primereact/dialog';
import ClientDialog from './ClientDialog';
import { Trans, useTranslation } from 'react-i18next';
import { Client } from '../../types/Client';

export const ClientManagement = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [clients, setClients] = useState<Client[]>(null);
  const [clientToDelete, setClientToDelete] = useState<Client>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<Client>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();

  const loadData = () => {
    setLoading(true);
    Promise.all([clientsService.getAll()]).then((response) => {
      const _clients = response[0];
      setClients(_clients);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const clientsTableHeader = (
    <div className="table-header">
      {t('clients.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addClient();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteClient = (client: Client) => {
    setClientToDelete(client);
    setDeleteDialogVisible(true);
  };

  const deleteClient = () => {
    setLoading(true);
    clientsService.delete(clientToDelete.id).then(() => {
      loadData();
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editClient = (_client: Client) => {
    setSelectedRowToEdit(_client);
    setAddEditDialogVisible(true);
  };

  const addClient = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteClient()} />
    </>
  );

  const bodyTemplate = (data: Client, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: Client) => (
    <span>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip={t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editClient(data);
        }}
      ></Button>
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          confirmDeleteClient(data);
        }}
      ></Button>
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={clients}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('clients.empty_message')}
            loading={loading}
            header={clientsTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>

            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <ClientDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            client={selectedRowToEdit}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {clientToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: clientToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
