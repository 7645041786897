import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import propertiesService from '../../service/api/PropertiesService';

import residentCardsService from '../../service/api/ResidentCardsService';
import { Dialog } from 'primereact/dialog';
import ResidentCardDialog from './ResidentCardDialog';
import { Trans, useTranslation } from 'react-i18next';
import { ResidentCard } from '../../types/ResidentCard';
import { Property } from '../../types/Property';
import { getFullAddress } from '../../utils/property';

export const ResidentCardManagement = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [residentCards, setResidentCards] = useState<ResidentCard[]>(null);
  const [residentCardToDelete, setResidentCardToDelete] = useState<ResidentCard>();

  const [properties, setProperties] = useState<Property[]>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<ResidentCard>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();

  const loadData = () => {
    setLoading(true);
    Promise.all([residentCardsService.getAll(), propertiesService.getAll()]).then((response) => {
      setProperties(response[1]);
      const propertiesDict = Object.assign(
        {},
        ...response[1].map((r) => {
          return { [r.id]: getFullAddress(r) };
        })
      );

      const _residentCards = response[0];
      _residentCards.forEach((_x) => {
        _x.propertyName = propertiesDict[_x.propertyId];
      });
      setResidentCards(_residentCards);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const residentCardsTableHeader = (
    <div className="table-header">
      {t('residentCards.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addResidentCard();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteResidentCard = (residentCard: ResidentCard) => {
    setResidentCardToDelete(residentCard);
    setDeleteDialogVisible(true);
  };

  const deleteResidentCard = () => {
    setLoading(true);
    residentCardsService.delete(residentCardToDelete.id).then(() => {
      loadData();
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editResidentCard = (_residentCard: ResidentCard) => {
    setSelectedRowToEdit(_residentCard);
    setAddEditDialogVisible(true);
  };

  const addResidentCard = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button
        label={t('common.yes')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => deleteResidentCard()}
      />
    </>
  );

  const bodyTemplate = (data: ResidentCard, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: ResidentCard) => (
    <span>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip={t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editResidentCard(data);
        }}
      ></Button>
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          confirmDeleteResidentCard(data);
        }}
      ></Button>
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={residentCards}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('residentCards.empty_message')}
            loading={loading}
            header={residentCardsTableHeader}
          >
            <Column field="number" header={t('residentCards.number')} sortable body={bodyTemplate}></Column>
            <Column field="propertyName" header={t('residentCards.property')} sortable body={bodyTemplate}></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <ResidentCardDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            residentCard={selectedRowToEdit}
            availableProperties={properties}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {residentCardToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: residentCardToDelete.number }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
