import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
const resources = {
  pl: {
    translation: require('./locales/pl/translations.json'),
  },
  en: {
    translation: require('./locales/en/translations.json'),
  },
};
i18next.use(initReactI18next).init({
  resources,
  lng: 'pl',
  interpolation: {
    escapeValue: false,
  },
  debug: process.env.REACT_APP_ENVIRONMENT !== 'production',
});
export default i18next;
