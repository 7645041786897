import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';

import propertiesService from '../../service/api/PropertiesService';
import residentCardsService from '../../service/api/ResidentCardsService';

import wasteReceptionsService from '../../service/api/WasteReceptionsService';
import WasteReceptionDialog from './WasteReceptionDialog';
import { useTranslation } from 'react-i18next';
import { WasteReception } from '../../types/WasteReception';
import { ResidentCard } from '../../types/ResidentCard';
import { getFullAddress } from '../../utils/property';
import { DateFilterToolbar } from './DateFilterToolbar';
import { format, parseISO, addDays } from 'date-fns';
import getPaginatorTemplate from '../_shared/getPaginatorTemplate';
import { getFileFromBuffer } from '../_shared/getFile';

export const ClosedReceptionListPage = () => {
  const [loading, setLoading] = useState(true);

  const [wasteReceptions, setWasteReceptions] = useState<WasteReception[]>(null);

  const [residentCards, setResidentCards] = useState<ResidentCard[]>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<WasteReception>(null);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);

  const emptyPaginatorState = {
    currentPage: 1,
    totalPages: 1,
    rows: 10,
  };

  const emptyPaginationParameters = {
    order: {
      isAscending: false,
      orderColumn: '',
    },
    page: {
      index: 1,
      size: 10,
    },
  };

  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);

  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);

  const handlePageChange = (pageNumber) => {
    setPaginatorState((prevState) => ({ ...prevState, currentPage: pageNumber }));
    setPaginationParameters((prevState) => {
      const clone = JSON.parse(JSON.stringify(prevState));
      clone.page.index = pageNumber;
      return clone;
    });
  };

  const loadData = () => {
    const paramStartDate = startDate ? new Date(new Date(startDate.getTime()).setHours(0, 0, 0)).toISOString() : null;
    const paramEndDate = endDate
      ? new Date(addDays(new Date(endDate.getTime()).setHours(0, 0, 0), 1)).toISOString()
      : null;
    setLoading(true);
    Promise.all([
      wasteReceptionsService.searchClosed({
        paginationParams: paginationParameters,
        filters: { startDate: paramStartDate, endDate: paramEndDate },
      }),
      residentCardsService.getAll(),
      propertiesService.getAll(),
    ]).then((response) => {
      setResidentCards(response[1]);
      const residentCardsDict = Object.assign({}, ...response[1].map((r) => ({ [r.id]: r.number })));
      const propertiesDict = Object.assign({}, ...response[2].map((p) => ({ [p.id]: getFullAddress(p) })));

      setPaginatorState((prevStat) => {
        const totalPages = Math.ceil(response[0].totalCount / prevStat.rows);
        return {
          ...prevStat,
          totalPages: totalPages,
          currentPage: prevStat.currentPage > totalPages ? 1 : prevStat.currentPage,
        };
      });

      const _wasteReceptions = response[0].data;
      _wasteReceptions.forEach((_x) => {
        _x.residentCardNumber = residentCardsDict[_x.residentCardId];
      });
      _wasteReceptions.forEach((_x) => {
        _x.propertyName = propertiesDict[_x.propertyId];
      });
      setWasteReceptions(_wasteReceptions);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, paginationParameters]);

  const wasteReceptionsTableHeader = <div className="table-header">{t('wasteReceptions.closed_receptions')}</div>;

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
  };

  const viewWasteReception = async (_wasteReception: WasteReception) => {
    const reception = await wasteReceptionsService.get(_wasteReception.id);

    setSelectedRowToEdit(reception);
    setAddEditDialogVisible(true);
  };

  const prepareFileName = (): string => {
    const formattedDate = format(Date.now(), 'yyyyMMdd_HHmmss');
    return `${t('wasteReceptions.closed_receptions')}__${formattedDate}.xlsx`;
  };

  const handleExport = async () => {
    const paramStartDate = startDate ? new Date(new Date(startDate.getTime()).setHours(0, 0, 0)).toISOString() : null;
    const paramEndDate = endDate
      ? new Date(addDays(new Date(endDate.getTime()).setHours(0, 0, 0), 1)).toISOString()
      : null;

    const response = await wasteReceptionsService.getReport({
      startDate: paramStartDate,
      endDate: paramEndDate,
    });

    getFileFromBuffer(response, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', prepareFileName());
  };

  const handleReceiptExport = async (reception: WasteReception) => {
    const response = await wasteReceptionsService.getReceipt(reception.id);
    // file name intentionally left blank to open pdf in new tab
    getFileFromBuffer(response, 'application/pdf', null);
  }

  const bodyTemplate = (data: WasteReception, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const dateTemplate = (reception: WasteReception) => {
    return reception.closedAt ? format(parseISO(reception.closedAt), 'yyyy-MM-dd HH:mm:ss') : '';
  };

  const actionTemplate = (data: WasteReception) => (
    <span>
      <Button
          type="button"
          icon="pi pi-print"
          tooltip={t('wasteReceptions.print_receipt')}
          tooltipOptions={{ position: 'top' }}
          className="p-button-success mr-2"
          onClick={() => {
            handleReceiptExport(data);
          }}
      ></Button>
      <Button
        type="button"
        icon="pi pi-search"
        tooltip={t('common.view_details')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          viewWasteReception(data);
        }}
      ></Button>
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DateFilterToolbar
            t={t}
            startDate={startDate}
            onStartDateChanged={(value) => {
              setStartDate(value);
              handlePageChange(1);
            }}
            endDate={endDate}
            onEndDateChanged={(value) => {
              setEndDate(value);
              handlePageChange(1);
            }}
            onExportClick={handleExport}
          />

          <DataTable
            value={wasteReceptions}
            paginator
            className="p-datatable-customers"
            rows={paginatorState.rows}
            dataKey="id"
            rowHover
            emptyMessage={t('wasteReceptions.empty_message')}
            loading={loading}
            header={wasteReceptionsTableHeader}
            paginatorTemplate={getPaginatorTemplate(paginatorState, handlePageChange)}
          >
            <Column field="propertyName" header={t('wasteReceptions.property')} body={bodyTemplate}></Column>
            <Column field="residentCardNumber" header={t('wasteReceptions.resident_card')} body={bodyTemplate}></Column>
            <Column field="closedAt" header={t('wasteReceptions.closed_at')} body={dateTemplate}></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <WasteReceptionDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            wasteReception={selectedRowToEdit}
            availableResidentCards={residentCards}
          />
        </div>
      </div>
    </div>
  );
};
