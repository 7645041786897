import axios from 'axios';
import { ResidentCard } from '../../types/ResidentCard';

class ResidentCardsService {
  async getAll(): Promise<ResidentCard[]> {
    const result = await axios.get('/resident-cards');
    return result.data;
  }
  async getAllForProperty(propertyId: string): Promise<ResidentCard[]> {
    const result = await axios.get(`/resident-cards/?propertyId=${propertyId}`);
    return result.data;
  }
  async get(id: string): Promise<ResidentCard> {
    const result = await axios.get(`/resident-cards/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/resident-cards/${id}`);
  }
  async create(postData: ResidentCard): Promise<void> {
    return axios.post(`/resident-cards`, mapToRequestBody(postData));
  }
  async edit(postData: ResidentCard, id: string): Promise<void> {
    return axios.put(`/resident-cards/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (residentCard: ResidentCard): any => {
  const result = {
    id: residentCard.id,
    number: residentCard.number,
    propertyId: residentCard.propertyId,
  };

  return result;
};

const residentCardsService = new ResidentCardsService();
export default residentCardsService;
