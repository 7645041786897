import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import usersService from '../../service/api/UsersService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { User } from '../../types/User';
import { Role } from '../../types/Role';
import { Division } from '../../types/Division';

interface FormValuesProps extends User {
  confPassword: string;
}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  login: '',
  firstName: '',
  lastName: '',
  password: '',
  confPassword: '',
  roleId: '',
  divisionId: null,
  active: true,
};

type Props = {
  userDialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  user: User;
  availableRoles: Role[];
  availableDivisions: Division[];
};

function UserDialog({ userDialogVisible, closeAddEditModal, user, availableRoles, availableDivisions }: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const loggedUserContext = useSelector((state: any) => state.user.context);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const { t } = useTranslation();

  const userRoles = availableRoles?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const divisions = availableDivisions?.map((r) => {
    return { label: r.name, value: r.id };
  });
  divisions?.unshift({ label: t('common.select'), value: null });

  useEffect(() => {
    if (userDialogVisible && user?.id) {
      setInitFormValues({
        id: user.id,
        login: user.login,
        firstName: user.firstName ? user.firstName : '',
        lastName: user.lastName ? user.lastName : '',
        password: '',
        confPassword: '',
        roleId: user.roleId,
        divisionId: user.divisionId,
        active: user.active,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.id && !data.password?.trim()) {
        errors.password = t('users.error_password_required');
      }
      if (data.password !== data.confPassword) {
        errors.confPassword = t('users.error_passwords_dont_match');
      }
      if (!data.roleId) {
        errors.roleId = t('users.error_role_required');
      }
      if (!data.login?.trim()) {
        errors.login = t('users.error_login_required');
      }
      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      if (!formData.id) {
        usersService
          .create(formData)
          .then((_res: any) => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('login', t('users.error_login_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        usersService
          .edit(formData, formData.id)
          .then(() => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('login', t('users.error_login_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const userDialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={userDialogVisible}
        header={t('users.user_details')}
        modal
        className="p-fluid"
        footer={userDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="login">{t('users.login')}</label>
              {user?.id ? (
                <InputText id="login" value={formik.values.login} readOnly disabled />
              ) : (
                <InputText
                  id="login"
                  value={formik.values.login}
                  onChange={formik.handleChange}
                  className={formik.touched.login && formik.errors.login && 'p-invalid'}
                />
              )}
              <FormErrorMessage fieldName="login" formikInstance={formik} />
            </div>
            <>
              <div className="field">
                <label htmlFor="password">{t('users.password')}</label>
                <Password
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  feedback={false}
                  toggleMask
                  readOnly={loggedUserContext.id === user?.id ? true : undefined}
                  disabled={loggedUserContext.id === user?.id ? true : undefined}
                  className={formik.touched.password && formik.errors.password && 'p-invalid'}
                />
                <FormErrorMessage fieldName="password" formikInstance={formik} />
              </div>
              <div className="field">
                <label htmlFor="confPassword">{t('users.password_repeat')}</label>
                <Password
                  id="confPassword"
                  name="confPassword"
                  value={formik.values.confPassword}
                  onChange={formik.handleChange}
                  feedback={false}
                  readOnly={loggedUserContext.id === user?.id ? true : undefined}
                  disabled={loggedUserContext.id === user?.id ? true : undefined}
                  className={formik.touched.confPassword && formik.errors.confPassword && 'p-invalid'}
                />
                <FormErrorMessage fieldName="confPassword" formikInstance={formik} />
              </div>
              <hr />
            </>
            <div className="field">
              <label htmlFor="firstName">{t('users.first_name')}</label>
              <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
            </div>
            <div className="field">
              <label htmlFor="lastName">{t('users.last_name')}</label>
              <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
            </div>

            <div className="field">
              <label htmlFor="roleId">{t('users.role')}</label>
              <Dropdown
                id="roleId"
                value={formik.values.roleId}
                onChange={formik.handleChange}
                options={userRoles}
                placeholder={t('common.select')}
                disabled={loggedUserContext.id === user?.id ? true : undefined}
                className={formik.touched.roleId && formik.errors.roleId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="roleId" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="divisionId">{t('users.division')}</label>
              <Dropdown
                id="divisionId"
                value={formik.values.divisionId}
                onChange={formik.handleChange}
                options={divisions}
                placeholder={t('common.select')}
                className={formik.touched.roleId && formik.errors.roleId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="divisionId" formikInstance={formik} />
            </div>

            <div className="field-checkbox">
              <Checkbox
                id="active"
                checked={formik.values.active}
                onChange={formik.handleChange}
                readOnly={loggedUserContext.id === user?.id ? true : undefined}
                disabled={loggedUserContext.id === user?.id ? true : undefined}
              />
              <label htmlFor="active">{t('common.active')}</label>
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default UserDialog;
