import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import propertiesService from '../../service/api/PropertiesService';
import residentCardsService from '../../service/api/ResidentCardsService';

import wasteReceptionsService from '../../service/api/WasteReceptionsService';
import wasteGroupsService from '../../service/api/WasteGroupsService';
import { Dialog } from 'primereact/dialog';
import WasteReceptionDialog from './WasteReceptionDialog';
import { Trans, useTranslation } from 'react-i18next';
import { WasteReception } from '../../types/WasteReception';
import { ResidentCard } from '../../types/ResidentCard';
import { WasteTypeDetails } from '../../types/WasteType';
import { WasteGroup } from '../../types/WasteGroup';
import { getFullAddress } from '../../utils/property';

export const OpenReceptionListPage = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [wasteReceptions, setWasteReceptions] = useState<WasteReception[]>(null);
  const [wasteReceptionToDelete, setWasteReceptionToDelete] = useState<WasteReception>();

  const [residentCards, setResidentCards] = useState<ResidentCard[]>();
  const [wasteGroups, setWasteGroups] = useState<WasteGroup[]>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<WasteReception>(null);
  const [wasteTypesForEdit, setWasteTypesForEdit] = useState<WasteTypeDetails[]>();
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();

  const loadData = () => {
    setLoading(true);
    Promise.all([
      wasteReceptionsService.getAllActive(),
      residentCardsService.getAll(),
      wasteGroupsService.getAll(),
      propertiesService.getAll(),
    ]).then((response) => {
      setResidentCards(response[1]);
      setWasteGroups(response[2]);
      const residentCardsDict = Object.assign({}, ...response[1].map((r) => ({ [r.id]: r.number })));
      const propertiesDict = Object.assign({}, ...response[3].map((p) => ({ [p.id]: getFullAddress(p) })));

      const _wasteReceptions = response[0];
      _wasteReceptions.forEach((_x) => {
        _x.residentCardNumber = residentCardsDict[_x.residentCardId];
      });
      _wasteReceptions.forEach((_x) => {
        _x.propertyName = propertiesDict[_x.propertyId];
      });
      setWasteReceptions(_wasteReceptions);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const wasteReceptionsTableHeader = (
    <div className="table-header">
      {t('wasteReceptions.open_receptions')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteWasteReception = (wasteReception: WasteReception) => {
    setWasteReceptionToDelete(wasteReception);
    setDeleteDialogVisible(true);
  };

  const deleteWasteReception = () => {
    setLoading(true);
    wasteReceptionsService.delete(wasteReceptionToDelete.id).then(() => {
      loadData();
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editWasteReception = async (_wasteReception: WasteReception) => {
    const reception = await wasteReceptionsService.get(_wasteReception.id);
    const types = await propertiesService.getSummary(_wasteReception.propertyId);

    setSelectedRowToEdit(reception);
    setWasteTypesForEdit(types);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button
        label={t('common.yes')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => deleteWasteReception()}
      />
    </>
  );

  const bodyTemplate = (data: WasteReception, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: WasteReception) => (
    <span>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip={t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editWasteReception(data);
        }}
      ></Button>
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          confirmDeleteWasteReception(data);
        }}
      ></Button>
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={wasteReceptions}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('wasteReceptions.empty_message')}
            loading={loading}
            header={wasteReceptionsTableHeader}
          >
            <Column field="propertyName" header={t('wasteReceptions.property')} sortable body={bodyTemplate}></Column>
            <Column
              field="residentCardNumber"
              header={t('wasteReceptions.resident_card')}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <WasteReceptionDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            wasteReception={selectedRowToEdit}
            availableResidentCards={residentCards}
            wasteTypes={wasteTypesForEdit}
            wasteGroups={wasteGroups}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {wasteReceptionToDelete && (
                <span>
                  <Trans
                    i18nKey="wasteReceptions.confirm_delete_message"
                    values={{ name: wasteReceptionToDelete.propertyName }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
