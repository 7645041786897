import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import propertiesService from '../../service/api/PropertiesService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Property } from '../../types/Property';

interface FormValuesProps extends Property {}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  city: '',
  street: '',
  houseNumber: '',
  apartmentNumber: '',
  zipCode: '',
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  property: Property;
};

function PropertyDialog({ dialogVisible, closeAddEditModal, property }: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && property) {
      setInitFormValues({
        id: property.id,
        city: property.city,
        street: property.street,
        houseNumber: property.houseNumber,
        apartmentNumber: property.apartmentNumber,
        zipCode: property.zipCode,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.city?.trim()) {
        errors.city = t('properties.error_city_required');
      }
      if (!data.street?.trim()) {
        errors.street = t('properties.error_street_required');
      }
      if (!data.houseNumber?.trim()) {
        errors.houseNumber = t('properties.error_house_number_required');
      }
      if (!data.zipCode?.trim()) {
        errors.zipCode = t('properties.error_zip_code_required');
      }
      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      if (!formData.id) {
        propertiesService
          .create(formData)
          .then((_res: any) => {
            hideDialog();
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        propertiesService
          .edit(formData, formData.id)
          .then(() => {
            hideDialog();
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('properties.property_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="city">{t('properties.city')}</label>
              <InputText
                id="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                className={formik.touched.city && formik.errors.city && 'p-invalid'}
              />
              <FormErrorMessage fieldName="city" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="street">{t('properties.street')}</label>
              <InputText
                id="street"
                value={formik.values.street}
                onChange={formik.handleChange}
                className={formik.touched.street && formik.errors.street && 'p-invalid'}
              />
              <FormErrorMessage fieldName="street" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="houseNumber">{t('properties.house_number')}</label>
              <InputText
                id="houseNumber"
                value={formik.values.houseNumber}
                onChange={formik.handleChange}
                className={formik.touched.houseNumber && formik.errors.houseNumber && 'p-invalid'}
              />
              <FormErrorMessage fieldName="houseNumber" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="apartmentNumber">{t('properties.apartment_number')}</label>
              <InputText
                id="apartmentNumber"
                value={formik.values.apartmentNumber}
                onChange={formik.handleChange}
                className={formik.touched.apartmentNumber && formik.errors.apartmentNumber && 'p-invalid'}
              />
              <FormErrorMessage fieldName="apartmentNumber" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="zipCode">{t('properties.zip_code')}</label>
              <InputText
                id="zipCode"
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                className={formik.touched.zipCode && formik.errors.zipCode && 'p-invalid'}
              />
              <FormErrorMessage fieldName="zipCode" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default PropertyDialog;
