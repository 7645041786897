import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { WasteTypeDetails } from '../../types/WasteType';
import { WasteGroup } from '../../types/WasteGroup';
import { Fieldset } from 'primereact/fieldset';
import { groupBy } from '../../utils/group-by';

type Props = {
  wasteTypes: WasteTypeDetails[];
  wasteGroups: WasteGroup[];
};

function UsedLimits({ wasteTypes, wasteGroups }: Props) {
  const { t } = useTranslation();

  const getWasteGroup = (id: string) => {
    return wasteGroups.find((wg) => wg.id === id);
  };

  const getSumForGroup = (wasteGroupId: string) => {
    return wasteTypes
      .filter((i) => i.wasteGroupId === wasteGroupId)
      .reduce((acc, current) => {
        return acc + current.usedLimit;
      }, 0);
  };

  const groupLimitExceeded = (wasteGroupId: string) => {
    const sum = getSumForGroup(wasteGroupId);
    const limit = getWasteGroup(wasteGroupId).limit;

    return limit != null && sum > limit;
  };

  const itemTemplate = (item: WasteTypeDetails, index) => {
    return (
      <React.Fragment key={index}>
        <div className="field col-4">
          <label htmlFor={`items[${index}].wasteLimit`}>{t('wasteReceptionItems.waste_type')}</label>
          <InputText id={`items[${index}].name`} value={item.name} readOnly={true} disabled={true} className="w-full" />
        </div>
        <div className="field col-4">
          <label htmlFor={`items[${index}].usedLimit`}>{t(`wasteGroups.used_limit`)}</label>
          <InputNumber
            id={`items[${index}].usedLimit`}
            disabled={true}
            readOnly={true}
            value={item.usedLimit}
            className={
              (item.limit && item.usedLimit > item.limit) || groupLimitExceeded(item.wasteGroupId)
                ? 'w-full p-invalid'
                : 'w-full'
            }
          />
        </div>
        <div className="field col-4">
          <label htmlFor={`items[${index}].wasteLimit`}>{t('wasteTypes.limit')}</label>
          <InputNumber
            id={`items[${index}].wasteLimit`}
            value={item.limit}
            readOnly={true}
            disabled={true}
            className="w-full"
          />
        </div>
      </React.Fragment>
    );
  };

  const createInputs = () => {
    const itemsGroupped = groupBy(wasteTypes, 'wasteGroupId');

    const arr = Array.from(itemsGroupped);
    return arr
      .map((ar, index) => {
        const [wasteGroupId, items] = ar;
        const wg = getWasteGroup(wasteGroupId);
        if (!wg) {
          return null;
        }
        const unit = t(`wasteGroups.units_short.${wg.unit}`);
        const usedLimit = items.reduce((acc, current) => {
          return acc + current.usedLimit;
        }, 0);
        return (
          <Fieldset
            toggleable={true}
            key={index}
            className="col-12"
            legend={`${wg.name}${
              wg.limit
                ? ` (${t('wasteGroups.limit')} ${wg.limit} ${unit} ${t('wasteGroups.used')} ${usedLimit} ${unit})`
                : ''
            }`}
          >
            <div className="col-12 grid">
              {items?.map((item, index) => {
                return itemTemplate(item, index);
              })}
            </div>
          </Fieldset>
        );
      })
      .filter(Boolean);
  };

  return (
    <div className="col-12 formgrid grid">
      <div className="field col-12">{createInputs()}</div>
    </div>
  );
}

export default UsedLimits;
