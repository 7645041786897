export enum WeighingType {
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE',
}

export enum WeighingStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface Weighing {
  id: string;

  number: string;

  wasteReceptionId: string;

  type: WeighingType;

  status: WeighingStatus;

  value?: number;

  valueGross?: number;

  valueTare?: number;
}
