import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import propertiesService from '../../service/api/PropertiesService';
import { Dialog } from 'primereact/dialog';
import PropertyDialog from './PropertyDialog';
import { Trans, useTranslation } from 'react-i18next';
import { Chip } from 'primereact/chip';
import { useHistory } from 'react-router-dom';
import { Property } from '../../types/Property';
import { getFullAddress } from '../../utils/property';

export const PropertyManagement = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [properties, setProperties] = useState<Property[]>(null);
  const [propertyToDelete, setPropertyToDelete] = useState<Property>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<Property>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const loadData = () => {
    setLoading(true);
    Promise.all([propertiesService.getAll()]).then((response) => {
      const _properties = response[0];
      setProperties(_properties);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const propertiesTableHeader = (
    <div className="table-header">
      {t('properties.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addProperty();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteProperty = (property: Property) => {
    setPropertyToDelete(property);
    setDeleteDialogVisible(true);
  };

  const deleteProperty = () => {
    setLoading(true);
    propertiesService.delete(propertyToDelete.id).then(() => {
      loadData();
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const editProperty = (_property: Property) => {
    setSelectedRowToEdit(_property);
    setAddEditDialogVisible(true);
  };

  const addProperty = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const viewDetails = (id: string) => {
    history.push(`/property-details/${id}`);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteProperty()} />
    </>
  );

  const bodyTemplate = (data: Property, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: Property) => (
    <span>
      <Button
        type="button"
        icon="pi pi-pencil"
        tooltip={t('common.edit')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          editProperty(data);
        }}
      ></Button>
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success mr-2"
        onClick={() => {
          confirmDeleteProperty(data);
        }}
      ></Button>
      <Button
        type="button"
        icon="pi pi-search"
        tooltip={t('properties.property_details')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          viewDetails(data.id);
        }}
      ></Button>
    </span>
  );

  const cardsTemplate = (data: any, props: any) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field].map((item: any) => {
          return <Chip key={item} label={item} className="mr-2 mb-2" />;
        })}
      </>
    );
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={properties}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('properties.empty_message')}
            loading={loading}
            header={propertiesTableHeader}
          >
            <Column field="city" header={t('properties.city')} sortable body={bodyTemplate}></Column>
            <Column field="street" header={t('properties.street')} sortable body={bodyTemplate}></Column>
            <Column field="houseNumber" header={t('properties.house_number')} sortable body={bodyTemplate}></Column>
            <Column
              field="apartmentNumber"
              header={t('properties.apartment_number')}
              sortable
              body={bodyTemplate}
            ></Column>
            <Column field="zipCode" header={t('properties.zip_code')} sortable body={bodyTemplate}></Column>
            <Column
              field="cards"
              filterField="cards"
              headerStyle={{ width: '40%' }}
              header={t('properties.cards')}
              body={cardsTemplate}
            ></Column>
            <Column
              headerStyle={{ width: '12rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <PropertyDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            property={selectedRowToEdit}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {propertyToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: getFullAddress(propertyToDelete) }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
