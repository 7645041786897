import axios from 'axios';
import { User } from '../../types/User';

class UsersService {
  async get(): Promise<User[]> {
    return axios.get('/users').then((res) => res.data);
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/users/${id}`);
  }
  async create(postData: User): Promise<void> {
    return axios.post(`/users`, mapToRequestBody(postData));
  }
  async edit(postData: User, id: string): Promise<void> {
    return axios.put(`/users/${id}`, mapToRequestBody(postData));
  }

  async changePassword(oldPassword: string, newPassword: string) {
    return axios.put('/users/me/changePassword', {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  }

  async changeLanguage(language: string) {
    return axios.put('/users/me/language', {
      language: language,
    });
  }

  async getLoggedUserContext() {
    return axios.get('/users/me/context');
  }
}

const mapToRequestBody = (user: User): any => {
  const result: any = {
    id: user.id,
    login: user.login,
    firstName: user.firstName,
    lastName: user.lastName,
    roleId: user.roleId,
    active: user.active,
    divisionId: user.divisionId,
  };

  if (user.password) {
    result.password = user.password;
  }

  return result;
};

let usersService = new UsersService();
export default usersService;
