import axios from 'axios';
import { Division } from '../../types/Division';

class DivisionsService {
  async getAll(): Promise<Division[]> {
    const result = await axios.get('/divisions');
    return result.data;
  }
  async get(id: string): Promise<Division> {
    const result = await axios.get(`/divisions/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/divisions/${id}`);
  }
  async create(postData: Division): Promise<void> {
    return axios.post(`/divisions`, mapToRequestBody(postData));
  }
  async edit(postData: Division, id: string): Promise<void> {
    return axios.put(`/divisions/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (division: Division): any => {
  const result = {
    id: division.id,
    name: division.name,
    clientId: division.clientId,
    scaleoLocationId: division.scaleoLocationId,
    apiUrl: division.apiUrl,
    apiIdentifier: division.apiIdentifier,
    apiSecret: division.apiSecret,
  };

  return result;
};

const divisionsService = new DivisionsService();
export default divisionsService;
