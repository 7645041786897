import axios from 'axios';
import { Role, RoleDetails } from '../../types/Role';

class RolesService {
  async get(): Promise<Role[]> {
    return axios.get('/roles').then((res) => res.data);
  }
  async getDetails(): Promise<RoleDetails[]> {
    return axios.get('/roles/details').then((res) => res.data);
  }
  async edit(data: RoleDetails, id: string): Promise<void> {
    return axios.put(`/roles/${id}`, data);
  }
}

let rolesService = new RolesService();
export default rolesService;
