import React, { useContext, useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import AuthContext from '../store/auth-context';
import { login } from '../service/AuthService';
import { AxiosConfigContainer } from '../components/AxiosConfig';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const Login = () => {
  const authCtx = useContext(AuthContext);
  const { i18n, t } = useTranslation();

  const [loginOrPasswordInvalid, setLoginlOrPasswordInvalid] = useState(false);
  const [loginEmpty, setLoginEmpty] = useState(false);
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const loginInputRef = useRef();
  const passwordInputRef = useRef();

  useEffect(() => {
    const language = localStorage.getItem('language');
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [i18n]);

  function validateLoginAndPassword(
    enteredLogin: string,
    setLoginEmpty: any,
    enteredPassword: string,
    setPasswordEmpty: any
  ) {
    if (enteredLogin === '') {
      setLoginEmpty(true);
    } else {
      setLoginEmpty(false);
    }
    if (enteredPassword === '') {
      setPasswordEmpty(true);
    } else {
      setPasswordEmpty(false);
    }
    return !(enteredLogin === '' || enteredPassword === '');
  }

  const submitHandler = (event: any) => {
    event.preventDefault();

    // @ts-ignore
    const enteredLogin = loginInputRef.current.value;
    // @ts-ignore
    const enteredPassword = passwordInputRef.current.value;

    const valid = validateLoginAndPassword(enteredLogin, setLoginEmpty, enteredPassword, setPasswordEmpty);

    if (valid) {
      login(enteredLogin, enteredPassword)
        .then((response) => {
          authCtx.login(response.data.access_token);
        })
        .catch(() => {
          setLoginlOrPasswordInvalid(true);
        });
    }
  };

  return (
    <>
      <AxiosConfigContainer />
      <form onSubmit={submitHandler}>
        <div className="login-body">
          <div className="login-panel"></div>

          <div className="login-content">
            <img src="assets/layout/images/logo-black.png" alt="babylon-layout" />

            <h1>
              <span>{t('users.app_name')}</span>
            </h1>
            <p>{t('users.login_subheader')}</p>

            <div className="mb-5">
              <div className="login-input-wrapper mb-0">
                <InputText
                  id="login"
                  type="text"
                  placeholder={t('users.login')}
                  ref={loginInputRef}
                  className={loginEmpty && 'p-invalid'}
                />
                <i className="pi pi-user"></i>
              </div>
              {loginEmpty && (
                <small id={`error-msg-login`} className="p-error">
                  {t('users.error_login_required')}
                </small>
              )}
            </div>

            <div className="mb-5">
              <div className="login-input-wrapper mb-0">
                <InputText
                  type="password"
                  placeholder={t('users.password')}
                  ref={passwordInputRef}
                  className={passwordEmpty && 'p-invalid'}
                />
                <i className="pi pi-lock"></i>
              </div>
              {passwordEmpty && (
                <small id={`error-msg-password`} className="p-error">
                  {t('users.error_password_required')}
                </small>
              )}
              {loginOrPasswordInvalid === true && (
                <small id={`error-msg-password`} className="p-error">
                  {t('users.error_invalid_login_or_password')}
                </small>
              )}
            </div>

            <Button type="submit" label={t('users.log_in')} />
            {/* <Button
              label="Sign In"
              onClick={() => {
                // history.push("/");
              }}
            /> */}
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
