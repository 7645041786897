import { FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import rolesService from '../../service/api/RolesService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { PickList, PickListChangeParams } from 'primereact/picklist';
import { RoleDetails } from '../../types/Role';
import { Permission } from '../../types/Permission';

interface FormValuesProps extends RoleDetails {}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  name: '',
  permissions: [],
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  role: RoleDetails;
  allPermissions: Permission[];
};

function RoleDialog({ dialogVisible, closeAddEditModal, role, allPermissions }: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const [availablePermissions, setAvailablePermissions] = useState<Permission[]>(null);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  useEffect(() => {
    setAvailablePermissions(allPermissions?.filter((ar) => !role?.permissions.find((rm) => rm.id === ar.id)));
  }, [allPermissions, role]);

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && role?.id) {
      setInitFormValues({
        id: role.id,
        name: role.name,
        permissions: role.permissions,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,

    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      rolesService
        .edit(formData, formData.id)
        .then(() => {
          hideDialog();
        })
        .finally(() => helpers.setSubmitting(false));
    },
    enableReinitialize: true,
  });

  const dialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  const itemTemplate = (item: Permission) => {
    return <div className="product-item">{item.name}</div>;
  };
  const onChange = (event: PickListChangeParams) => {
    setAvailablePermissions(event.source);
    formik.setFieldValue('permissions', event.target);
  };

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('roles.role_details')}
        modal
        className="p-fluid"
        footer={dialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText id="name" value={formik.values.name} readOnly disabled />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
              <br />
            </div>
            <div className="field">
              <PickList
                source={availablePermissions}
                target={formik.values.permissions}
                itemTemplate={itemTemplate}
                sourceHeader={t('roles.available')}
                targetHeader={t('roles.selected')}
                sourceStyle={{ height: '262px' }}
                targetStyle={{ height: '262px' }}
                onChange={onChange}
                showSourceControls={false}
                showTargetControls={false}
              ></PickList>
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default RoleDialog;
