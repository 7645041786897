import axios from 'axios';
import { Property } from '../../types/Property';
import { WasteTypeDetails } from '../../types/WasteType';

class PropertiesService {
  async getAll(): Promise<Property[]> {
    const result = await axios.get('/properties');
    return result.data;
  }
  async get(id: string): Promise<Property> {
    const result = await axios.get(`/properties/${id}`);
    return result.data;
  }
  async getSummary(id: string): Promise<WasteTypeDetails[]> {
    const result = await axios.get(`/properties/${id}/summary/`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/properties/${id}`);
  }
  async create(postData: Property): Promise<void> {
    return axios.post(`/properties`, mapToRequestBody(postData));
  }
  async edit(postData: Property, id: string): Promise<void> {
    return axios.put(`/properties/${id}`, mapToRequestBody(postData));
  }
  async find(cardNumber: string): Promise<string> {
    const response = await axios.get(`/properties/find?card=${cardNumber}`);
    return response.data;
  }

}

const mapToRequestBody = (property: Property): any => {
  const result = {
    id: property.id,
    city: property.city,
    street: property.street,
    houseNumber: property.houseNumber,
    apartmentNumber: property.apartmentNumber,
    zipCode: property.zipCode,
  };

  return result;
};

const propertiesService = new PropertiesService();
export default propertiesService;
