import { Form, Formik, FormikErrors } from 'formik';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import FormErrorMessage from '../_shared/FormErrorMessage';
import { useHistory } from 'react-router-dom';
import propertiesService from '../../service/api/PropertiesService';
import { Button } from 'primereact/button';
import { Toast } from "primereact/toast";

export const Dashboard = () => {
  const { t } = useTranslation();

  const toast = useRef<any>();

  const history = useHistory();

  const validate = (values: { cardNumber: string }) => {
      let errors: FormikErrors<{ cardNumber }> = {};
      if (!values.cardNumber) {
          errors.cardNumber = t('dashboard.card_is_required');
      }
      return errors;
  }
  const searchProperty = async (cardNumber: string) => {
      try {
          const propertyId = await propertiesService.find(cardNumber);
          history.push(`/property-details/${propertyId}?card=${cardNumber}`);
      } catch (error: any) {
          let message = undefined;
          switch (error.response.status) {
              case 404: {
                  message = t('dashboard.card_not_found_error');
                  break;
              }
              case 409: {
                  message = t('dashboard.configuration_error');
                  break;
              }
              case 503: {
                  message = t('dashboard.external_api_error');
                  break;
              }
          }

          toast?.current?.show({
              severity: 'error',
              summary: t('common.error'),
              detail: message,
              life: 4000,
          });
        }
  }

  const navigateToProperties = () => {
      history.push('/property');
  }

  return (
    <div className="grid p-3">
        <Toast ref={toast} />
      <div className="col-12 text-center text-6xl font-bold">{t('dashboard.waste_reception')}</div>
      <div className="col-6 text-center">
        <Formik
          initialValues={{ cardNumber: '' }}
          validate={validate}
          onSubmit={async (values) => searchProperty(values.cardNumber.trim())}
        >
          {({ handleChange, errors, touched }) => (
            <Form>
              <div className="field">
                <label htmlFor="cardNumber" className="text-5xl font-bold">
                  {t('dashboard.card_scan')}
                </label>
                <div>
                  <InputText
                    id="cardNumber"
                    name="cardNumber"
                    onChange={handleChange}
                    className={touched.cardNumber && errors.cardNumber && 'p-invalid'}
                  />
                  <div>
                    <FormErrorMessage fieldName="cardNumber" formikInstance={{ touched, errors }} />
                  </div>
                </div>
              </div>
              <Button type="submit" icon="pi pi-search" label={t('common.search')} className="p-button ml-2"></Button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="col-6 text-center text-5xl font-bold">
        <div>{t('dashboard.properties_search')}</div>
        <div className="p-3">
          <Button type="button" icon="pi pi-search" label={t('common.search')} className="p-button ml-2" onClick={navigateToProperties}></Button>
        </div>
      </div>
    </div>
  );
};
