import axios from 'axios';
import { Permission } from '../../types/Permission';

class PermissionsService {
  async get(): Promise<Permission[]> {
    return axios.get('/permissions').then((res) => res.data);
  }
}

let permissionsService = new PermissionsService();
export default permissionsService;
