import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import wasteGroupsService from '../../service/api/WasteGroupsService';

import wasteTypesService from '../../service/api/WasteTypesService';
import { Dialog } from 'primereact/dialog';
import WasteTypeDialog from './WasteTypeDialog';
import { Trans, useTranslation } from 'react-i18next';
import { WasteGroup } from '../../types/WasteGroup';
import { WasteType } from '../../types/WasteType';

export const WasteTypeManagement = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [wasteTypes, setWasteTypes] = useState<WasteType[]>(null);
  const [wasteTypeToDelete, setWasteTypeToDelete] = useState<WasteType>();

  const [wasteGroups, setWasteGroups] = useState<WasteGroup[]>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<WasteType>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();

  const loadData = () => {
    setLoading(true);
    Promise.all([wasteTypesService.getAll(), wasteGroupsService.getAll()]).then((response) => {
      setWasteGroups(response[1]);
      const wasteGroupsDict = Object.assign({}, ...response[1].map((r) => ({ [r.id]: r.name })));

      const _wasteTypes = response[0];
      _wasteTypes.forEach((_x) => {
        _x.wasteGroupName = wasteGroupsDict[_x.wasteGroupId];
      });
      setWasteTypes(_wasteTypes);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const wasteTypesTableHeader = (
    <div className="table-header">
      {t('wasteTypes.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addWasteType();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteWasteType = (wasteType: WasteType) => {
    setWasteTypeToDelete(wasteType);
    setDeleteDialogVisible(true);
  };

  const deleteWasteType = () => {
    setLoading(true);
    wasteTypesService.delete(wasteTypeToDelete.id).then(() => {
      loadData();
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const addWasteType = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteWasteType()} />
    </>
  );

  const bodyTemplate = (data: WasteType, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: WasteType) => (
    <span>
      <Button
        type="button"
        icon="pi pi-trash"
        tooltip={t('common.delete')}
        tooltipOptions={{ position: 'top' }}
        className="p-button-success"
        onClick={() => {
          confirmDeleteWasteType(data);
        }}
      ></Button>
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={wasteTypes}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('wasteTypes.empty_message')}
            loading={loading}
            header={wasteTypesTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>
            <Column field="code" header={t('wasteTypes.code')} sortable body={bodyTemplate}></Column>
            <Column field="limit" header={t('wasteTypes.limit')} sortable body={bodyTemplate}></Column>
            <Column field="wasteGroupName" header={t('wasteTypes.waste_group')} sortable body={bodyTemplate}></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <WasteTypeDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            wasteType={selectedRowToEdit}
            availableWasteGroups={wasteGroups}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {wasteTypeToDelete && (
                <span>
                  <Trans
                    i18nKey="common.confirm_delete_message"
                    values={{ name: wasteTypeToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
