import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import clientsService from '../../service/api/ClientsService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Client } from '../../types/Client';

interface FormValuesProps extends Client {}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  name: '',
  scaleoCustomerId: null,
  scaleoClientId: null,
  scaleoClientSecret: null,
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  client: Client;
};

function ClientDialog({ dialogVisible, closeAddEditModal, client }: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && client) {
      setInitFormValues({
        id: client.id,
        name: client.name,
        scaleoCustomerId: client.scaleoCustomerId,
        scaleoClientId: client.scaleoClientId,
        scaleoClientSecret: client.scaleoClientSecret,
    });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }

      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      if (!formData.id) {
        clientsService
          .create(formData)
          .then((_res: any) => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('clients.error_name_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        clientsService
          .edit(formData, formData.id)
          .then(() => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('clients.error_name_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('clients.client_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="scaleoCustomerId">{t('clients.scaleo_customer_id')}</label>
              <InputText
                  id="scaleoCustomerId"
                  value={formik.values.scaleoCustomerId}
                  onChange={formik.handleChange}
                  className={formik.touched.scaleoCustomerId && formik.errors.scaleoCustomerId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="scaleoCustomerId" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="scaleoClientId">{t('clients.scaleo_client_id')}</label>
              <InputText
                  id="scaleoClientId"
                  value={formik.values.scaleoClientId}
                  onChange={formik.handleChange}
                  className={formik.touched.scaleoClientId && formik.errors.scaleoClientId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="scaleoClientId" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="scaleoClientSecret">{t('clients.scaleo_client_secret')}</label>
              <InputText
                  id="scaleoClientSecret"
                  value={formik.values.scaleoClientSecret}
                  onChange={formik.handleChange}
                  className={formik.touched.scaleoClientSecret && formik.errors.scaleoClientSecret && 'p-invalid'}
              />
              <FormErrorMessage fieldName="scaleoClientSecret" formikInstance={formik} />
            </div>

          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default ClientDialog;
