export enum Unit {
  ITEM = 'item',
  KILOGRAM = 'kilogram',
  CUBIC_METER = 'cubic_meter',
}

export interface WasteGroup {
  id: string;
  name: string;
  limit: number;
  unit: Unit;
  displayUnit?: string;
}
