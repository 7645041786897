import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { Property } from '../../types/Property';

type Props = {
  property: Property;
};

function PropertyDetailsSection({ property }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className="card">
        <h5>{t('properties.property_details')}</h5>
        <div className="grid">
          <div className="field col-6">
            <label htmlFor="city">{t('properties.city')}</label>
            <InputText id="city" value={property.city} disabled={true} className=" w-full" />
          </div>

          <div className="field col-6">
            <label htmlFor="street">{t('properties.street')}</label>
            <InputText id="street" value={property.street} disabled={true} className=" w-full" />
          </div>

          <div className="field col-6">
            <label htmlFor="houseNumber">{t('properties.house_number')}</label>
            <InputText id="houseNumber" value={property.houseNumber} disabled={true} className=" w-full" />
          </div>

          <div className="field col-6">
            <label htmlFor="apartmentNumber">{t('properties.apartment_number')}</label>
            <InputText id="apartmentNumber" value={property.apartmentNumber} disabled={true} className=" w-full" />
          </div>

          <div className="field col-6">
            <label htmlFor="zipCode">{t('properties.zip_code')}</label>
            <InputText id="zipCode" value={property.zipCode} disabled={true} className=" w-full" />
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyDetailsSection;
