import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classNames } from 'primereact/utils';
import { useHistory } from 'react-router-dom';

import { Avatar } from 'primereact/avatar';
import AuthContext from './store/auth-context';
import { useTranslation } from 'react-i18next';
import usersService from './service/api/UsersService';
import { userActions } from './store/user-slice';
import { locale } from 'primereact/api';

const AppTopbar = (props: any) => {
  const loggedUserContext = useSelector((state: any) => state.user.context);
  const selectedLanguage = useSelector((state: any) => state.user.context.language);
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();

  const availableLanguages = [
    { displayText: 'PL', value: 'pl' },
  ];

  const { i18n, t } = useTranslation();
  const onLogoutClicked = () => {
    authCtx.logout();
  };

  const getAvatarLabel = () => {
    const initials = [loggedUserContext?.firstName?.charAt(0), loggedUserContext?.lastName?.charAt(0)].join('');
    if (initials) {
      return initials.toUpperCase();
    }

    return loggedUserContext?.login.charAt(0).toUpperCase();
  };

  const changeLanguage = async (value: any) => {
    await usersService.changeLanguage(value);
    await i18n.changeLanguage(value);
    locale(value);
    dispatch(userActions.setLanguage(value));
  };

  const history = useHistory();
  const topbarMenuClassName = classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': props.topbarMenuActive });

  const activeTopbarItemClassName = (name: string) => {
    return name === props.activeTopbarItem ? 'active-topmenuitem' : null;
  };

  return (
    <div className="layout-topbar">
      <button className="layout-topbar-logo p-link" onClick={() => history.push('/')}>
        <img id="layout-topbar-logo" src="assets/layout/images/logo-white.png" alt="babylon-layout" />
      </button>

      <button className="layout-menu-button p-link" onClick={props.onMenuButtonClick}>
        <i className="pi pi-bars"></i>
      </button>

      <button id="topbar-menu-button" className="p-link" onClick={props.onTopbarMenuButtonClick}>
        <i className="pi pi-ellipsis-v"></i>
      </button>

      <ul className={topbarMenuClassName}>
        <li className={activeTopbarItemClassName('profile')}>
          <button className="p-link" onClick={(e) => props.onTopbarItemClick(e, 'profile')}>
            <Avatar label={getAvatarLabel()} size="large" shape="circle"></Avatar>
          </button>

          <ul className="fadeInDown">
            <li role="menuitem">
              <button className="p-link" onClick={props.onChangePasswordClick}>
                <i className="pi pi-fw pi-undo"></i>
                <span>{t('users.change_password')}</span>
              </button>
            </li>
            <li role="menuitem">
              <button className="p-link" onClick={onLogoutClicked}>
                <i className="pi pi-fw pi-sign-out"></i>
                <span>{t('users.log_out')}</span>
              </button>
            </li>
          </ul>
        </li>
        <li className={activeTopbarItemClassName('language')}>
          <div hidden={true}>
            <button className="p-link" onClick={(e) => props.onTopbarItemClick(e, 'language')}>
            <span style={{ fontSize: '1.5rem' }}>
              {availableLanguages.find((a) => a.value === selectedLanguage).displayText}
            </span>
            </button>
          </div>

          <ul className="fadeInDown">
            {availableLanguages.map((language) => {
              return (
                <li key={language.value} role="menuitem">
                  <button className="p-link" onClick={() => changeLanguage(language.value)}>
                    {language.value === selectedLanguage && <i className="pi pi-check"></i>}
                    {language.value === selectedLanguage ? (
                      <span>{language.displayText}</span>
                    ) : (
                      <span style={{ marginLeft: '22px' }}>{language.displayText}</span>
                    )}
                  </button>
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AppTopbar;
