import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import divisionsService from '../../service/api/DivisionsService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Division } from '../../types/Division';
import { Client } from '../../types/Client';

interface FormValuesProps extends Division {}

const INIT_FORM_STATE: FormValuesProps = {
  id: null,
  name: '',
  clientId: '',
  scaleoLocationId: null,
  apiUrl: null,
  apiIdentifier: null,
  apiSecret: null,
};

type Props = {
  dialogVisible: boolean;
  closeAddEditModal: VoidFunction;
  division: Division;
  availableClients: Client[];
};

function DivisionDialog({ dialogVisible, closeAddEditModal, division, availableClients }: Props) {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    closeAddEditModal();
  };

  const clients = availableClients?.map((r) => {
    return { label: r.name, value: r.id };
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (dialogVisible && division) {
      setInitFormValues({
        id: division.id,
        name: division.name,
        clientId: division.clientId,
        scaleoLocationId: division.scaleoLocationId,
        apiUrl: division.apiUrl,
        apiIdentifier: division.apiIdentifier,
        apiSecret: division.apiSecret,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogVisible]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: FormValuesProps) => {
      let errors: FormikErrors<FormValuesProps> = {};

      if (!data.name?.trim()) {
        errors.name = t('common.error_name_required');
      }
      if (!data.clientId) {
        errors.clientId = t('divisions.error_client_required');
      }

      return errors;
    },
    onSubmit: async (formData: FormValuesProps, helpers: FormikHelpers<FormValuesProps>) => {
      if (!formData.id) {
        divisionsService
          .create(formData)
          .then((_res: any) => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('divisions.error_name_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        divisionsService
          .edit(formData, formData.id)
          .then(() => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('divisions.error_name_already_taken'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const DialogFooter = (
    <>
      <Button
        type="reset"
        label={t('common.cancel')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        type="submit"
        label={t('common.save')}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={dialogVisible}
        header={t('divisions.division_details')}
        modal
        className="p-fluid"
        footer={DialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="col-10">
            <div className="field">
              <label htmlFor="name">{t('common.name')}</label>
              <InputText
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.touched.name && formik.errors.name && 'p-invalid'}
              />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="clientId">{t('divisions.client')}</label>
              <Dropdown
                id="clientId"
                value={formik.values.clientId}
                onChange={formik.handleChange}
                options={clients}
                placeholder={t('common.select')}
                emptyMessage={t('divisions.clients_empty_message')}
                className={formik.touched.clientId && formik.errors.clientId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="clientId" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="scaleoLocationId">{t('divisions.scaleo_location_id')}</label>
              <InputText
                  id="scaleoLocationId"
                  value={formik.values.scaleoLocationId}
                  onChange={formik.handleChange}
                  className={formik.touched.scaleoLocationId && formik.errors.scaleoLocationId && 'p-invalid'}
              />
              <FormErrorMessage fieldName="scaleoLocationId" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="apiUrl">{t('divisions.api_url')}</label>
              <InputText
                  id="apiUrl"
                  value={formik.values.apiUrl}
                  onChange={formik.handleChange}
                  className={formik.touched.apiUrl && formik.errors.apiUrl && 'p-invalid'}
              />
              <FormErrorMessage fieldName="apiUrl" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="apiIdentifier">{t('divisions.api_identifier')}</label>
              <InputText
                  id="apiIdentifier"
                  value={formik.values.apiIdentifier}
                  onChange={formik.handleChange}
                  className={formik.touched.apiIdentifier && formik.errors.apiIdentifier && 'p-invalid'}
              />
              <FormErrorMessage fieldName="apiIdentifier" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="apiSecret">{t('divisions.api_secret')}</label>
              <InputText
                  id="apiSecret"
                  value={formik.values.apiSecret}
                  onChange={formik.handleChange}
                  className={formik.touched.apiSecret && formik.errors.apiSecret && 'p-invalid'}
              />
              <FormErrorMessage fieldName="apiSecret" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default DivisionDialog;
