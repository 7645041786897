import { Property } from '../types/Property';

export function getFullAddress(property: Property): string {
  let fullAddress = `${property.city} ${property.street} ${property.houseNumber}`;
  if (property.apartmentNumber) {
    fullAddress += `/${property.apartmentNumber}`;
  }

  return fullAddress;
}
