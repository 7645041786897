import React, { useState } from 'react';
import { Calendar, CalendarChangeParams } from 'primereact/calendar';

import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';

export const DateFilterToolbar = (props) => {
  const { t, startDate, onStartDateChanged, endDate, onEndDateChanged, onExportClick } = props;

  const [loading, setLoading] = useState(false);

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Calendar
          placeholder={t('common.date_from')}
          locale="pl"
          inputId="startDate"
          value={startDate}
          className={`mr-1 mt-2 col-11 md:col-6`}
          onChange={(element: CalendarChangeParams) => {
            onStartDateChanged(element.value);
          }}
          showIcon
        />

        <Calendar
          placeholder={t('common.date_to')}
          locale="pl"
          inputId="endDate"
          value={endDate}
          className={`mr-1 mt-2 col-11 md:col-6`}
          onChange={(element: CalendarChangeParams) => {
            onEndDateChanged(element.value);
          }}
          showIcon
        />
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Export"
        className="p-button"
        icon="pi pi-file-excel"
        onClick={async () => {
          setLoading(true);
          await onExportClick();
          setLoading(false);
        }}
        loading={loading}
      />
    );
  };

  return <Toolbar className="mb-4 grid" left={leftToolbarTemplate} right={rightToolbarTemplate} />;
};
