import axios from 'axios';
import { Weighing } from '../../types/Weighing';

const defaultConfig = { timeout: 60_000 };

class WeighingsService {
  async getAllForWasteReception(wasteReceptionId: string): Promise<Weighing[]> {
    const result = await axios.get(`/weighings/?wasteReceptionId=${wasteReceptionId}`);
    return result.data;
  }

  async get(id: string): Promise<Weighing> {
    const result = await axios.get(`/weighings/${id}`);
    return result.data;
  }

  async create(postData: { wasteReceptionId: string }): Promise<void> {
    return axios.post(`/weighings`, postData, defaultConfig);
  }

  async closeWeighing(id: string): Promise<void> {
    return axios.post(`/weighings/${id}/close`, null, defaultConfig);
  }
}

const weighingsService = new WeighingsService();
export default weighingsService;
