import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import wasteGroupsService from '../../service/api/WasteGroupsService';
import { Dialog } from 'primereact/dialog';
import WasteGroupDialog from './WasteGroupDialog';
import { Trans, useTranslation } from 'react-i18next';
import { WasteGroup } from '../../types/WasteGroup';

export const WasteGroupManagement = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [loading, setLoading] = useState(true);

  const [wasteGroups, setWasteGroups] = useState<WasteGroup[]>(null);
  const [wasteGroupToDelete, setWasteGroupToDelete] = useState<WasteGroup>();

  const [selectedRowToEdit, setSelectedRowToEdit] = useState<WasteGroup>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const { t } = useTranslation();

  const loadData = () => {
    setLoading(true);
    Promise.all([wasteGroupsService.getAll()]).then((response) => {
      const _wasteGroups = response[0];
      _wasteGroups.forEach((group) => {
        group.displayUnit = t(`wasteGroups.units.${group.unit}`);
      });
      setWasteGroups(_wasteGroups);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const wasteGroupsTableHeader = (
    <div className="table-header">
      {t('wasteGroups.header')}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder={t('common.search')}
        />
        <Button
          type="button"
          icon="pi pi-plus"
          label={t('common.add')}
          className="p-button ml-2"
          onClick={() => {
            addWasteGroup();
          }}
        ></Button>
      </span>
    </div>
  );

  const closeAddEditModal = () => {
    setAddEditDialogVisible(false);
    loadData();
  };

  const confirmDeleteWasteGroup = (wasteGroup: WasteGroup) => {
    setWasteGroupToDelete(wasteGroup);
    setDeleteDialogVisible(true);
  };

  const deleteWasteGroup = () => {
    setLoading(true);
    wasteGroupsService.delete(wasteGroupToDelete.id).then(() => {
      loadData();
    });

    setDeleteDialogVisible(false);
  };
  const hideDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const addWasteGroup = () => {
    setSelectedRowToEdit(null);
    setAddEditDialogVisible(true);
  };

  const deleteDialogFooter = (
    <>
      <Button label={t('common.no')} icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteDialog()} />
      <Button label={t('common.yes')} icon="pi pi-check" className="p-button-text" onClick={() => deleteWasteGroup()} />
    </>
  );

  const bodyTemplate = (data: WasteGroup, props: ColumnBodyOptions) => {
    return <>{data[props.field]}</>;
  };

  const actionTemplate = (data: WasteGroup) => (
    <span>
      <Button
        type="button"
        icon="pi pi-trash"
        className="p-button-success"
        onClick={() => {
          confirmDeleteWasteGroup(data);
        }}
      ></Button>
    </span>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            value={wasteGroups}
            paginator
            className="p-datatable-customers"
            rows={10}
            dataKey="id"
            rowHover
            globalFilter={globalFilter}
            emptyMessage={t('wasteGroups.empty_message')}
            loading={loading}
            header={wasteGroupsTableHeader}
          >
            <Column field="name" header={t('common.name')} sortable body={bodyTemplate}></Column>
            <Column field="limit" header={t('wasteGroups.limit')} sortable body={bodyTemplate}></Column>
            <Column field="displayUnit" header={t('wasteGroups.unit')} sortable body={bodyTemplate}></Column>
            <Column
              headerStyle={{ width: '8rem' }}
              header={t('common.actions')}
              align="center"
              body={actionTemplate}
            ></Column>
          </DataTable>
          <WasteGroupDialog
            dialogVisible={addEditDialogVisible}
            closeAddEditModal={closeAddEditModal}
            wasteGroup={selectedRowToEdit}
          />
          <Dialog
            visible={deleteDialogVisible}
            className="p-fluid"
            header={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {t('common.confirm')}
              </span>
            }
            modal
            footer={deleteDialogFooter}
            onHide={hideDeleteDialog}
            breakpoints={{ '896px': '90vw' }}
            style={{ minWidth: '450px' }}
          >
            <div className="confirmation-content">
              {wasteGroupToDelete && (
                <span>
                  <Trans
                    i18nKey="wasteGroups.confirm_delete_message"
                    values={{ name: wasteGroupToDelete.name }}
                    components={[<strong />]}
                  />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
