import axios from 'axios';
import { WasteGroup } from '../../types/WasteGroup';

class WasteGroupsService {
  async getAll(): Promise<WasteGroup[]> {
    const result = await axios.get('/waste-groups');
    return result.data;
  }
  async get(id: string): Promise<WasteGroup> {
    const result = await axios.get(`/waste-groups/${id}`);
    return result.data;
  }
  async delete(id: string): Promise<void> {
    return axios.delete(`/waste-groups/${id}`);
  }
  async create(postData: WasteGroup): Promise<void> {
    return axios.post(`/waste-groups`, mapToRequestBody(postData));
  }
  async edit(postData: WasteGroup, id: string): Promise<void> {
    return axios.put(`/waste-groups/${id}`, mapToRequestBody(postData));
  }
}

const mapToRequestBody = (wasteGroup: WasteGroup): any => {
  const result = {
    id: wasteGroup.id,
    name: wasteGroup.name,
    limit: wasteGroup.limit,
    unit: wasteGroup.unit,
  };

  return result;
};

const wasteGroupsService = new WasteGroupsService();
export default wasteGroupsService;
